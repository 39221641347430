import React, {useRef, useState , useEffect} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AddHeaderSlider from 'src/components/headers/AddHeaderSlider';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoList from 'src/components/page-info/PageInfoList';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const urlApi = '/pages';
const urlRouter = '/site-pages';

export async function LoaderPagesUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  };
  return false;
};


const pagesForm = ({user_data}) => {
  if(!Permissions(user_data, 'Pages', 'EDIT_PAGES')){
      return <PermissionsNoPage />;
  }
  const navigate = useNavigate();
  const { data } = useLoaderData();
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [synonym, setSynonym] = useState((data != undefined) ? data.synonym : '');
  const [mainImg, setmainImg] = useState((data != undefined) ? data.images : '');
  const [sliders, setSliders] = useState((data != undefined && data.slider != undefined)? data.slider.id: false);
  const editorRef = useRef(null);
  const popup = MyToastify();
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Викликає popup з власного компонента
  // Текст для popup
  const popupTextPostSuccess = "Сторінку створено";
  const popupTextPutSuccess = "Сторінку змінено";

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        synonym: synonym,
        content: editorRef.current.getContent(),
        active: 'on',
        images: mainImg,
      };
      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          if (response.status === 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPutSuccess);
          }
        });
      } else {
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log('post pages response', response);
          if (response.status === 201) {
            popup.toastifySuccess(popupTextPostSuccess);
            return navigate(`${urlRouter}/update/${response.data.id}`);
          }
        });
      };
    };
  };

  return (
    <>
    <ToastContainer />
    <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} parentUrl={`pages/${data?.id}`}/> 
     <CForm onSubmit={handleSubmit}>
       <div className="mb-3">
         <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
         <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
       </div>
       <div className="mb-3">
         <CFormLabel htmlFor="exampleFormControlInput1">Синонім</CFormLabel>
         <CFormInput type="text" id="exampleFormControlInput2" value={synonym} onChange={(e) => setSynonym(e.target.value)} autoComplete="off" />
       </div>
       <div className="mb-3">
         <CFormLabel htmlFor="exampleFormControlInput1">Головне зображення</CFormLabel>
         <CFormInput type="text" id="exampleFormControlInput3" value={mainImg} onChange={(e) => setmainImg(e.target.value)} autoComplete="off" />
         <Link to="/media" target='_blanck'>перейти до медіа</Link>
       </div>
       <div className="mb-3">
         <Editor
           apiKey={apiKey}
           onInit={(evt, editor) => editorRef.current = editor}
           initialValue={(data != undefined) ? data.content : ''}
           init={{
             height: 500,
             menubar: 'edit view insert format',
             plugins: [
               'advlist','autolink',
               'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
               'insertdatetime','media','table', 'help', 'code'
            ],
             toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
               'alignleft aligncenter alignright alignjustify | ' +
               'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
             content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
             font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
           }}
         />
       </div>
       <CButton type="submit" color="success">Зберегти</CButton>
     </CForm>

     {(data != undefined)&&
       <PageInfoList id={data.id} type="page" />
     }
   </>

  );
};

export default pagesForm;
