import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { _nav_admin, _nav_manager } from '../_nav'
import AxiosInstance from './Axios'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import Roles from 'src/views/roles/Roles'


const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  const [nav, setNav] = useState([]);
  const [userRole, setUserRole] = useState(false);
  if (!userRole) {
    setUserRole(localStorage.getItem('user_role'));
  }
  const getMenu = () => {
    let res = AxiosInstance.get('/menus', {params:{"role.name": userRole} , headers: {"accept": "application/json"}}).then((response) => {
      if(response.status == 200 && response.data.length > 0){
        setNav(response.data[0].menuItems.map((k) => {
          if(k.children != undefined && k.children.length > 0){
            return {
                component: CNavGroup,
                name:  k.name,
                // icon: <GiCannedFish className='nav-icon' />,
                items: k.children.map((c) => (
                  {
                    component: CNavItem,
                    name: c.name,
                    to: c.slug,
                    // icon: <BiCategoryAlt className="nav-icon" />,
                  }
                ))

            }
          }else{
            return {
              component: CNavItem,
              name: k.name,
              to: k.slug,
              // icon: <BiCategoryAlt className="nav-icon" />,
            }
          }
          
      }))
      }else{
        if (userRole == 'ROLE_ADMIN')
          setNav(_nav_admin)
        if (userRole == 'ROLE_MANAGER')
          setNav(_nav_manager)
      }
        // setReletedMenu(response.data['hydra:member'].map((k) => (
        //   {value: k.id, label: k.name}
        // )))
      
        
    });
  }
  useEffect(() => {
    getMenu();
  }, [userRole])

  console.log(userRole);
  if(!userRole || userRole == null)
    return <></>;


  // useEffect(() => {
    const siteBarBackgroundColor = process.env.REACT_APP_SITE_BAR_BACKGROUND_COLOR;
    console.log(siteBarBackgroundColor)
    if (siteBarBackgroundColor) {
      const style = document.createElement('style');
      style.innerHTML = `
        .site-bar-background {
          background-color: ${siteBarBackgroundColor};
        }
      `;
      document.head.appendChild(style);
    }
  // }, []);


  const Logo = process.env.REACT_APP_LOGO;
  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
      className='site-bar-background'
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <img src={Logo} width={200} />
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
        {/* <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          {(typeof nav == 'object') && <AppSidebarNav items={nav} />}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
