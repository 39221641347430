import React, { useEffect, useRef, useState } from 'react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton, CRow, CCol, CCard, CCardHeader, CCardBody,
  CFormTextarea,
  CFormSelect
} from '@coreui/react';
import {Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import Select     from 'react-select'
import {DataGrid} from '@mui/x-data-grid';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {   
  cilPen,
  cilPlus,
  cilTrash
} from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import MediaFrom from '../media/MediaFrom';
import AddHeaderSlider from '../headers/AddHeaderSlider';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { transliterate } from 'transliteration';
import { unique } from 'src/REDUX/store';

const initialValues = {
    // id: "",
    key: "",
    value: "",
    sort: 0,
};

const PageInfoForm = ({id, parent_id, actionShowModal, data, toastifySuccess, type}) => {

    const [values, setValues] = useState(initialValues);
    const [media, setMedia] = useState();
    const [mediaUrl, setMediaUrl] = useState(false);
    const [infoData, setInfoData] = useState([{
      key: '',
      value: ''
    }]);
    const [sliders, setSliders] = useState((data != undefined && data.slider != undefined)? data.slider.match(/(\d+)/)?.[0]: false);
    const popup = MyToastify();
    const [dataTemplates, setDataTemplates] = useState([]);
    const [dataTemplatesSelect, setDataTemplatesSelect] = useState([]);
    const [currentTemplate, setCurrentTemplate] = useState('');

    useEffect(() => {
        if(data != undefined){
          // console.log(data);
          setValues({
            key: data.key,
            value: data.value,
            sort: data.sort
          });

          setInfoData(data.data);
          
        }
        
    }, [data])

    useEffect(() => {
      // console.log(media);
      if(media != undefined){
        setValues({
          ...values,
            media: `/api/media_objects/${media}`
          });

          const response = AxiosInstance.get(`/media_objects/${media}`).then((response) => {
            if(response.status == 200){
              setMediaUrl(response.data.contentUrl)
            }
          });
      }
    }, [media]);
    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
    
        setValues({
          ...values,
          [name]: type === "number" ? parseInt(value, 10) : value
        });
      };

    const handleInputInfodata = (e) => {
        const { name, value, type } = e.target;
        let rowkey = parseInt( e.target.attributes.rowkey.value );
        setInfoData((prevInfoData) => 
          prevInfoData.map((item, index) =>  index === rowkey ? { ...item, [name]: value } : item
          )
        );  

      };
      /** Відправка форми */
      const handleSubmit = event => {
        event.preventDefault();
    
        if (data == undefined) {
          // parent_id
          if(type == 'page')
            values['page'] = `/api/pages/${parent_id}`;
          else if(type == 'objects')
            values['object'] = `/api/objects/${parent_id}`;
          else if(type == 'project')
            values['project'] = `/api/projects/${parent_id}`;


          if(sliders && sliders != '')
            values['slider'] = `/api/sliders/${sliders}`;

          console.log('infoData', infoData);
          if(infoData.length > 0)
            values['data'] = infoData;
          // переводим key в lowercase для запобіганню проблем з фільрацією на фронті
          const filtredValues = {...values, key : transliterate(values.key).toLowerCase().replace(/ /g, '_')};  
          const response = AxiosInstance.post('/page_infos', filtredValues).then((response) => {
            if(response.status == 201){
              actionShowModal(false);
              popup.toastifySuccess('Дані збережено');
              // navigate(`/menu/update/${response.data.id}`);
            }
          });
        }else{
          
          console.log('sliders', sliders);
          if(sliders && sliders != '')
            values['slider'] = `/api/sliders/${sliders}`;
          
          console.log('infoData', infoData);
          if(infoData.length > 0)
            values['data'] = infoData;
          
          console.log('values', values);
          // переводим key в lowercase для запобіганню проблем з фільрацією на фронті
          const filtredValues = {...values, key : transliterate(values.key).toLowerCase().replace(/ /g, '_')};
          const response = AxiosInstance.put('/page_infos/' + data.id, filtredValues).then((response) => {
            if(response.status == 200){
              actionShowModal(false);
              popup.toastifySuccess('Дані збережено');
            }
            // if(response.status == 201){
            //   navigate(`/menu/update/${response.data.id}`);
            // }
          });
        }
            // const response = AxiosInstance.put('/menus/' + data.id, values);
         
        // }
    
      }

     
      useEffect(() => {
        // if(sliders != undefined){
        //   const response = AxiosInstance.put(`pages/${data.id}`, {slider:`/api/sliders/${sliders}`}).then(()=>{
        //     popup.toastifySuccess('Слайдер додано');
        //   });
        // }
        console.log('sliders', sliders);
      }, [sliders]);

      const addData = () => {
        setInfoData([
          ...infoData,
          {
            key: '',
            value: '',
          }
        ]);
      }

      const delData = (key) => {
        const fiterData= infoData.filter((data, index) => {
          return index !== key;
        })
        setInfoData(fiterData);
      }

      useEffect(() => {
        AxiosInstance.get('/page_infos').then((response) => {
            const filterInfos = response.data['hydra:member'].map(info=>{
              return {
                label: info.key,
                value: info.id
              }
            });

            setDataTemplates(response.data['hydra:member']);
            setDataTemplatesSelect([...unique(filterInfos, 'label'), { label: 'Шаблони модулів', value: ''}]);
        });
      },[])

      function setTemplate(e) {
        const changedValues = dataTemplates.find((temp)=>{
          return temp.id == e.target.value;
        })
        setCurrentTemplate(e.target.value)

        if (changedValues !== undefined) {
          setValues({...values, key: changedValues.key, value: changedValues.value, sort: 0 });
          setInfoData(changedValues.data);
          setSliders(changedValues.slider?.match(/(\d+)/)?.[0] || false);
        }
        else {
          setValues({...values, key: '', value: '', sort: 0});
          setInfoData([]);
          setSliders(false);
        }
      }

    return(
        <>
            <CForm onSubmit={handleSubmit} className="mt-3">
                <div className="mb-3">
                    <CFormSelect
                      aria-label="Default select example"
                      onChange={setTemplate}
                      defaultValue={currentTemplate}
                      options={dataTemplatesSelect.toReversed()}
                    />
                </div>

                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Ключ</CFormLabel>
                    <CFormInput type="text" name="key" defaultValue={values.key} onChange={handleInputChange}  />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Значення</CFormLabel>
                    <CFormTextarea  name="value" defaultValue={values.value} onChange={handleInputChange} />
                </div>
                <div className="mb-3">
                    <CFormLabel htmlFor="exampleFormControlInput1">Сортування</CFormLabel>
                    <CFormInput type="number" name="sort" step={1} min={0} id="exampleFormControlInput2" value={values.sort} onChange={handleInputChange} autoComplete="off" />
                </div>

                <AddHeaderSlider id={(sliders !== false) ? sliders : undefined} newSlider={setSliders} infosId = {data?.id} /> 
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Додаткові поля</Accordion.Header>
                    <Accordion.Body className="p-3">
                      {
                        infoData.map( (val, key) => {
                          // console.log('val', val);
                          // console.log('key', key);
                          if(val != undefined) 
                            return (
                              <div className="row mt-1" key={key}>
                                <div className="col">
                                  <CFormInput type="text" name="key" defaultValue={val.key} rowkey={key} onChange={handleInputInfodata}  />
                                </div>
                                <div className="col">
                                  <CFormInput type="text" name="value" defaultValue={val.value} rowkey={key} onChange={handleInputInfodata}  />
                                </div>
                                <div className="col-1">
                                <CButton color="danger" variant="outline" onClick={() => {delData(key)}}>
                                  <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                                </CButton>
                              </div>
                              </div>
                            )
                        } )
                    }
                      <div className="row">
                        <div className="col d-flex justify-content-center">
                          <Button variant="secondary"  onClick={() => {addData()}} className="mb-2 mt-2">+</Button>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                  
                </Accordion>
                
                <hr/>

                <CButton type="submit" color="success">Зберегти</CButton>
            </CForm>
        </>
    );
}

export default PageInfoForm;