import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import Select from 'react-select'
import BasicModal from 'src/components/Modal';
import MediaFrom from 'src/components/media/MediaFrom';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const urlApi = '/users';
const urlRouter = '/users';
const role = localStorage.getItem('user_role');
const userData = JSON.parse(localStorage.getItem('user_data'));


export async function LoaderUsersUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
}

const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const UsersForm = ({user_data}) => {
  if(!Permissions(user_data, 'User', 'EDIT_USER')){
      return <PermissionsNoPage />;
  }
  // const [roleList, setRoleList] = useState([
  //   { value: 'ROLE_ADMIN', label: 'Адміністратор' },
  //   { value: 'ROLE_MANAGER', label: 'Менеджер' },
  //   { value: 'ROLE_CLIENT', label: 'Клієнт' },
  //   { value: 'ROLE_USER', label: 'Без ролі' },
  // ]);

  const [roleList, setRoleList] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [userRoleRemoved, setUserRoleRemoved] = useState([]);
  const navigate = useNavigate();
  const { data } = useLoaderData();

  useEffect(() => {
    getLocations();
    getRoleList();
    if(data != undefined){
      let r = data.userRoles.map((result) => ({
        label: `${result.role.label} - ${result.role.name}`,
        value: result.role.id,
      }));
      setUserRoleList(r)
      // Видалити ролі
      setUserRoleRemoved(data.userRoles.map((result) => ({
        // label: `${result.role.label} - ${result.role.name}`,
        value: result.id,
      })))
    }

    if (role == 'ROLE_MANAGER')
      setRoleList([{ value: 'ROLE_CLIENT', label: 'Клієнт' }])
  }, []);



  const [username, setUsername] = useState((data != undefined) ? data.username : '');
  const [plainPassword, setPlainPassword] = useState((data != undefined) ? data.plainPassword : '');
  const [firstName, setFirstName] = useState((data != undefined) ? data.firstName : '');
  const [lastName, setLastName] = useState((data != undefined) ? data.lastName : '');
  const [phone, setPhone] = useState((data != undefined) ? data.phone : '');
  const [email, setEmail] = useState((data != undefined) ? data.email : '');
  const [telegram, setTelegram] = useState((data != undefined) ? data.telegram : '');
  const [viber, setViber] = useState((data != undefined) ? data.viber : '');
  const [workSchedule, setWorkSchedule] = useState((data != undefined) ? data.workSchedule : '');
  const [roles, setRoles] = useState(() => {
    let rez;
    if (data != undefined && data.roles != undefined && data.roles.length > 0) {
      roleList.map((v) => {
        if (v.value == data.roles[0])
          rez = v;
      })
    }
    return rez;
  });
  // console.log(roles);
  // const [address, setAddress] = useState((data != undefined) ? data.address : '');
  // const [status, setStatus] = useState((data != undefined) ? data.status : '');
  // const [codeUser, setCodeUser] = useState((data != undefined) ? data.codeUser : '');
  // const [codeManager, setCodeManager] = useState((data != undefined) ? data.codeManager : '');
  const editorRef = useRef(null);

  const [location, setLocation] = useState((data != undefined && data.location != undefined) ? { label: data.location.name, value: data.location.id } : {});
  const [locationList, setLocationList] = useState([]);
  
  // Запит за одиницями виміру
  const getLocations = async (inputValue) => {
    let rez = await AxiosInstance.get(`/locations`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
    setLocationList(rez);
    return rez;
  };

  const getRoleList = async (inputValue) => {
    let rez = await AxiosInstance.get(`/roles`, { headers: { 'accept': 'application/json' } }).then((response) => {
      // console.log("response", response);
      return response.data.map((result) => ({
        label: `${result.label} - ${result.name}`,
        value: result.id,
      }));
    });
    setRoleList(rez);
    return rez;
  };


  const SetUserRoles = async (dataCreated=false) => { 
    if(!dataCreated) 
      await userRoleRemoved.map((r) => {
        const response =  AxiosInstance.delete(`/user_roles/${r.value}`).then((response) => {

        });
      });
    let user_id = (!dataCreated)? data.id: dataCreated.id;
    
    await userRoleList.map((v) => {
      const response =  AxiosInstance.post(`/user_roles`, {
        users: `/api/users/${user_id}`,
        role: `/api/roles/${v.value}`,
      }).then((response) => {

      });
    });
  }


  const handleSubmit = event => {
    event.preventDefault();
    // if (editorRef.current) {
    // console.log(editorRef.current.getContent());
    const data_response = {
      username: username,
      plainPassword: plainPassword,
      firstName: firstName,
      lastName: lastName,
      // roles: [roles.value],
      // address: address,
      // status: status,
      // codeUser: codeUser,
      // codeManager: codeManager,
      phone: phone,
      email: email,
      telegram: telegram,
      viber: viber,
      workSchedule: workSchedule,
      active: 'on',
    };
    // data_response['userRoles'] = userRoleList.map((v) => (
    //  {
    //   users: `/api/roles/${data.id}`,
    //   role: `/api/roles/${v.value}`,
    // }
    // ));
    if (location.value != undefined) {
      data_response['location'] = `/api/locations/${location.value}`;
    }
    if (data != undefined) {
      const response = AxiosInstance.put(`${urlApi}/${data.id}`, data_response).then((response) => {
        if (response.status === 200){
          SetUserRoles();
          return navigate(urlRouter);
        }
      });
    } else {
      const response = AxiosInstance.post(`${urlApi}`, data_response).then((response) => {
        if (response.status === 201){
          SetUserRoles(response.data);
          return navigate(urlRouter);
        }
      });
    }

    



    // }
  }

  const handleChangeRole = (value) => {
    console.log(value);
    setUserRoleList(value);
  };

  const actionShowModalMedia = (rez) => {
    setShowModalMedia(rez);
    console.log(11111);
    if (!rez)
      getMedia();
  };

  const [showModalMedia, setShowModalMedia] = useState(true);

  return (
    <>
      {(data != undefined) &&
        <div className="mb-3">
          {(data.mediaObjects != undefined && data.mediaObjects.length > 0) && <img src={process.env.REACT_APP_SERVER_URL + data.mediaObjects[0].contentUrl} alt="blog-image" height={300} className='m-5' />}

          <BasicModal
            show={showModalMedia}
            actionShowModal={actionShowModalMedia}
            title={`Додати зображення користувача`}
            btn_name='Додати зображення'
            content={
              <MediaFrom user_id={data.id} actionShowModal={actionShowModalMedia} />
            }
          />
        </div>
      }
      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Логін</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={username} onChange={(e) => setUsername(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Пароль</CFormLabel>
          <CFormInput type="password" id="exampleFormControlInput2" value={plainPassword} onChange={(e) => setPlainPassword(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Роль</CFormLabel>
          {/* <Select
            options={roleList}
            value={roles}
            onChange={(value) => {
              handleChangeRole(value);
            }}
          /> */}
          <Select
            value={userRoleList}
            isMulti
            name="colors"
            options={roleList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(value) => {
              handleChangeRole(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Локація</CFormLabel>
          <Select
            options={locationList}
            value={location}
            onChange={(value) => {
              setLocation(value);
            }}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Ім'я</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput3" value={firstName} onChange={(e) => setFirstName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Прізвище</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput4" value={lastName} onChange={(e) => setLastName(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput5">Телефон</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput5" value={phone} onChange={(e) => setPhone(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput6">Email</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput6" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput7">Telegram</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput7" value={telegram} onChange={(e) => setTelegram(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput8">Viber</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput8" value={viber} onChange={(e) => setViber(e.target.value)} autoComplete="off" />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput9">Графік роботи (якщо залишити поле пустим, то виведеться стандартний графік компанії, якщо заповнити, то виводитись буде Ваш графік)</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput9" value={workSchedule} onChange={(e) => setWorkSchedule(e.target.value)} autoComplete="off" />
        </div>

        {/* <div className="mb-3">
          <Editor
            apiKey='dvfh34ptn34xsf8t99ap6flco4fcps87169njgk0khjz1ylp'
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
        </div> */}

        <CButton type="submit" color="success">Зберегти</CButton>
        <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary">Відміна</CButton></Link>
      </CForm>
    </>
  );
};

export default UsersForm;