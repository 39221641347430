import React, { useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import axios from "axios";
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { confirmAlert } from 'react-confirm-alert';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const page = 1;
const postPerPage = 0;
const pageSize = 20;

export async function LoaderPages({ params }) {
  let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages?page=' + page, header).then((response) => {
    return response;
  });
  return res;
};

const Pages = ({user_data}) => {
  if(!Permissions(user_data, 'Pages', 'VIEW_PAGES')){
      return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  };

  const getRowsTable = (p = 1) => {
    axios.get(process.env.REACT_APP_SERVER_URL + '/api/pages?page=' + p, header).then((response) => {
      setRows(response.data['hydra:member']);
    });
  };

  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = axios.delete(process.env.REACT_APP_SERVER_URL + '/api/pages/' + row.id, header).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Назва', width: 200 },
    { field: 'newsShot', headerName: 'Опис', width: 400 },
    {
      field: 'created_at',
      headerName: 'Дата',
      type: 'date',
      width: 160,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={'/site-pages/update/' + params.row.id}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Сторінки</strong> <small></small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to="/site-pages/create"><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={30}
                rowsPerPageOptions={[30]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Pages;