import React, { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import {useLoaderData} from 'react-router-dom';
import axios from "axios";
import PermissionsNoPage, {Permissions} from "src/components/Permissions";


const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

export async function LoaderBlogUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await axios.get(process.env.REACT_APP_SERVER_URL + '/api/blogs/' + params.newsId).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const blogForm = ({user_data}) => {
  if(!Permissions(user_data, 'Blog', 'VIEW_BLOG')){
    return <PermissionsNoPage />;
  }
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined)? data.newsShot : '');
  const editorRef = useRef(null);
  const handleSubmit = event => {
    event.preventDefault();
    alert('You have submitted the form.')
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
      const article = {
        name: name,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
      };
      if (data != undefined){
        const response = axios.put(process.env.REACT_APP_SERVER_URL + '/api/news/' + data.id , article);
      }else {
        const response = axios.post(process.env.REACT_APP_SERVER_URL + '/api/news', article);
      }
    }
  }



    return (
      <>
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
          </div>
          <div className="mb-3">
            <Editor
              apiKey={apiKey}
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={(data != undefined)? data.newsFull : ''}
              init={{
                height: 500,
                menubar: 'edit view insert format',
                plugins: [
                  'advlist','autolink',
                  'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                  'insertdatetime','media','table', 'help', 'code'
               ],
                toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                  'alignleft aligncenter alignright alignjustify | ' +
                  'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
                content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
                font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
              }}
            />
          </div>
          <CButton type="submit" color="success">Submit</CButton>
        </CForm>
      </>
    );
};

export default blogForm;
