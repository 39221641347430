import React, { useRef, useState, useEffect  } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton
} from '@coreui/react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import AddItemGallery from 'src/components/gallery/AddItemGallery';
import AxiosInstance from 'src/components/Axios';
import BasicModal from 'src/components/Modal';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import GalleryList from 'src/components/gallery/GalleryList';
import Select from 'react-select'
import { confirmAlert } from 'react-confirm-alert';
import CIcon from '@coreui/icons-react';
import { cilPen, cilPlus, cilTrash, cilClone, cilPrint } from '@coreui/icons';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";






export async function LoaderRolesUpdate({ params }) {
  if(Object.keys(params).length != 0) {
    let res = await AxiosInstance.get('/roles/' + params.id).then((response) => {
      return response;
    });
    return res;
  }
  return  false;
}
const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}
const RolesForm = ({user_data}) => {
  if(!Permissions(user_data, 'Roles', 'VIEW_ROLES')){
      return <PermissionsNoPage />;
  }
  const {data}  = useLoaderData();
  const [name, setName] = useState((data != undefined)? data.name : '');
  const [label, setLabel] = useState((data != undefined)? data.label : '');
  const [entity, setEntity] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [permission, setPermission] = useState({});
  const [permissionEntity, setPermissionEntity] = useState({});
  const [dataEntity, setDataEntity] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const [menuActive, setMenuActive] = useState({});
  const [reletedMenu, setReletedMenu] = useState([]);
  const [saveMenu, setSetSaveMenu] = useState(0);



  const navigate = useNavigate();
  const popup = MyToastify();


  const getPermissions = () => {
    let res = AxiosInstance.get('/permissions', {params:{role:data.id}}).then((response) => {
      if(response.status == 200)(
        setPermissionList(response.data['hydra:member'])
      )
        
    });
  }
  const getMainMenuList = () => {
    let res = AxiosInstance.get('/menus', {params:{role:data.id}}).then((response) => {
      if(response.status == 200)(
        setReletedMenu(response.data['hydra:member'].map((k) => (
          {value: k.id, label: k.name}
        )))
      )
        
    });
  }

  const getMenuList = () => {
    let res = AxiosInstance.get('/menus').then((response) => {
      if(response.status == 200)(
        setMenuList(response.data['hydra:member'].map((k) => (
          {value: k.id, label: k.name}
        )))
      )
        
    });
  }

  useEffect(() => {
    if(data !=  undefined){
      let res = AxiosInstance.get('/entity_lists').then((response) => {
        if(response.status == 200){
          setDataEntity( response.data['hydra:member']);
          setEntity(response.data['hydra:member'].map((k) => (
            {value: k.key, label: k.name}
          )));
        }
          
      });
      getMainMenuList();
      getPermissions();
      getMenuList();
    }

  },[])


  const handleSubmit = event => {
    event.preventDefault();

   
    
    const article = {
        name: name,
        label: `ROLE_${label}`,
        // newsShot: newsShot,
        // newsFull: editorRef.current.getContent(),
      };
      if (data != undefined){
        const response = AxiosInstance.put('/roles/' + data.id , article).then((response) => {
          if(response.status == 200){
            popup.toastifySuccess('Галерею оновлено');
          }
        });
      }else {
        const response = AxiosInstance.post('/roles', article).then((response) => {
          console.log(response);
          if (response.status == 201) {
            popup.toastifySuccess("Галерею створено")
            navigate(`/roles/update/${response.data.id}`);
            
          }
        });
      }
    
  }


  function Deleted(row) {
    // console.log('row', row);
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      // message: 'Are you sure to do this.',
      message: (
        <>
         
        </>
      ),
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete('/permissions/' + row.id).then((response) => {
              getPermissions();
              // Вивести popup про успішне видалення замовлення
              popup.toastifySuccess('Видалено');
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };

  const handleSubmitPermission = event => {
    event.preventDefault();

    console.log(permissionEntity);
    let operationEntity = permissionEntity.value.toUpperCase();
    let entity_id = dataEntity.filter((d) => d.key == permissionEntity.value);
   
    console.log(entity_id);
    const article = {
        role: `/api/roles/${data.id}`,
        entity: `App\/Entity\/${permissionEntity.value}`,
        operation: `${permission.value}_${operationEntity}`,
        entityList: `/api/entity_lists/${entity_id[0].id}`,
        // newsShot: newsShot,
        // newsFull: editorRef.current.getContent(),
      };

      const response = AxiosInstance.post('/permissions', article).then((response) => {
        console.log(response);
        if (response.status == 201) {
          popup.toastifySuccess("Дозвіл додано")

          getPermissions();

          setPermission([]);
          setPermissionEntity([]);
          
        }
      });
      
    
  }

  const [showModal, setShowModal] = useState(true);

  const actionShowModal = (rez) => {
    setShowModal(rez);
    setUpdateLists(!updateLists);
  }

  const [updateLists, setUpdateLists] = useState(false);

  const handleChangeKey = (e) => {
    // Отримуємо значення з поля вводу
    const input = e.target.value;

    // Оновлюємо стан, залишаючи тільки великі латинські літери
    const filteredInput = input.toUpperCase().replace(/[^A-Z]/g, '').replace('ROLE', '');

    setName(filteredInput);
  };


  const saveMenus = (obj) => {
    console.log('obj', obj);
    AxiosInstance.put(`/roles/${data.id}`, {
        menus: obj.map(val => `/api/menus/${val.value}`)
    }).then(response => {
      getMainMenuList();
    })
  }

  const handleSubmitMenu = event => {
    event.preventDefault();
    let obj = reletedMenu;
    obj.push(menuActive)
    setReletedMenu([
      ...reletedMenu,
      menuActive
    ])
    
    saveMenus(obj)
  }

  function DeletedMenu(row) {
    // console.log('row', row);
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      // message: 'Are you sure to do this.',
      message: (
        <>
         
        </>
      ),
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            setReletedMenu([
              reletedMenu.filter(val => val.value != row.value)
            ])
            saveMenus(reletedMenu.filter(val => val.value != row.value))
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  };


  useEffect(() => {
    console.log(reletedMenu);
  }, [reletedMenu])

    return (
      <>
        <ToastContainer />
        <CForm onSubmit={handleSubmit}>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Ключ</CFormLabel>
            <div className='row'>
              <div className='col-1'>ROLE_{name}</div>
              <div className='col'>
                <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={handleChangeKey} required/>
              </div>

            </div>
            
          </div>
          <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput1" value={label} onChange={(e) => setLabel(e.target.value)} required/>
          </div>
          <CButton type="submit" color="success">Зберегти</CButton>
        </CForm>
        <hr/>
        <Accordion defaultActiveKey="" >
          <Accordion.Item eventKey="0">
            <Accordion.Header>Дозволи</Accordion.Header>
            <Accordion.Body>
                <ListGroup as="ol" numbered>
                { permissionList.map((v) => {
                  return (
                    <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                      <div className="ms-2 me-auto">
                        <div className="fw-bold">
                          {v.entityList.name} - {v.operation}  
                        </div>
                      </div>
                      <CButton color="danger" variant="outline" 
                      onClick={() => {
                        Deleted(v)
                      }
                      }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>
                    </ListGroup.Item>
                  )
                })
            

                }
                </ListGroup>
                <hr/>
                <CForm onSubmit={handleSubmitPermission}>
                  <div className='row'>
                    <div className='col-2'>
                    <Select
                      options={[
                        {label: 'Переглядати', value: 'VIEW'},
                        {label: 'Створювати/Редагувати', value: 'EDIT'}
                      ]}
                      value={permission}
                      onChange={(value) => {
                        setPermission(value)
                      }}
                      required
                    />
                    </div>
                    <div className='col-2'>
                      <Select
                        options={entity}
                        onChange={(value) => {
                          setPermissionEntity(value)
                        }}
                        value={permissionEntity}
                        required
                      />
                    </div>
                    <div className='col-1'>
                      <CButton type="submit" color="success">Зберегти</CButton>
                    </div>
                  </div>
                </CForm>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Меню</Accordion.Header>
                <Accordion.Body>
                
                  <ListGroup as="ol" numbered>
                  { reletedMenu.map((v) => {
                    return (
                      <ListGroup.Item as="li" className="d-flex justify-content-between align-items-start">
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">
                            {v.label}
                          </div>
                        </div>
                        <CButton color="danger" variant="outline" 
                        onClick={() => {
                          DeletedMenu(v)
                        }
                        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>
                      </ListGroup.Item>
                    )
                  })
              

                  }
                  </ListGroup>

                  <CForm onSubmit={handleSubmitMenu}>
                  <div className='row'>
                    <div className='col-2'>
                      <Select
                        options={menuList}
                        onChange={(value) => {
                          setMenuActive(value)
                        }}
                        value={menuActive}
                        required
                      />
                    </div>
                    <div className='col-1'>
                      <CButton type="submit" color="success">Зберегти</CButton>
                    </div>
                  </div>
                  </CForm>
                        
                </Accordion.Body>
              </Accordion.Item>
              
          </Accordion>

          
          
        
      </>
    );
};

export default RolesForm;
