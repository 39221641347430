/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import ProductCategories from 'src/components/product/ProductCategories';
import NewsCategories from 'src/components/news/NewsCategories';
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { Alert } from 'react-bootstrap';
import NewsMedia from 'src/components/news/NewsMedia';
import PermissionsNoPage, {Permissions} from "src/components/Permissions";

const urlApi = '/news';
const urlRouter = '/news';
// const role = localStorage.getItem('user_role');

const page = 1;
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

const popup = MyToastify();

export async function LoaderNewsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.newsId}`).then((response) => {
      return response;
    });
    return res;
  }
  return false;
};


const newsForm = () => {
  if(!Permissions(user_data, 'News', 'EDIT_NEWS')){
      return <PermissionsNoPage />;
  }
  const { data } = useLoaderData();
  const [myData, setMyData] = useState((data != undefined) ? data : false);

  useEffect(() => {
    setMyData(data);
  }, [data]);

  const navigate = useNavigate();
  
  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [newsShot, setNewsShot] = useState((data != undefined) ? data.newsShot : '');
  const [videoImg, setVideoImg] = useState((data != undefined) ? data.videoImg : '');
  const [newsDate, setNewsDate] = useState((data != undefined)? new Date(data.date).toISOString() : new Date().toISOString());
  const editorRef = useRef(null);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));

  useEffect(() => {
    setMyData(data);
  }, [data]);

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPutSuccess = "Оновлено";
  const popupTextPostSuccess = "Створено";
  const popupTextPutWarning = "Не оновлено";
  const popupTextPostWarning = "Не створено";
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        active: "on",
        top: "on",
        videoImg: videoImg,
        date: newsDate,
        newsShot: newsShot,
        newsFull: editorRef.current.getContent(),
        date: date,
        // active: ""
      };

      // Для оновлення новини
      if (data != undefined) {
        // console.log('News put article', article);
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          // console.log("put news response", response);
          if (response.status === 200) {
            popup.toastifySuccess(popupTextPutSuccess);
            setMyData(response.data);
          } else {
            popup.toastifyWarning(popupTextPutWarning);
          }
        });
      } else {
        // Для створення новини
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log("response post news", response);
          if (response.status === 201) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupTextPostSuccess);
            // alert("Дані оновлено");
            navigate(`${urlRouter}/update/${response.data.id}`);
          } else {
            popup.toastifyWarning(popupTextPostWarning);
          }
        });
      }
    }
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Назва категорії та зображення */}
      {
        (myData != undefined && myData != false ) &&
        <>
          <h1 className="mb-3">{myData.name}</h1>
          <NewsMedia
            myData={myData} urlApi={urlApi}
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning}
          />
        </>
      }

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Короткий опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={newsShot} onChange={(e) => setNewsShot(e.target.value)} />
        </div>
        <Alert variant='info'>Для коректного відображення на сайті текст має бути без зайвих стилів. Для очищення тексту копіюйте його з "Блокнот" або з "Notepad++", або набирати текст в редакторі нижче</Alert>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.newsFull : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist','autolink',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                'insertdatetime','media','table', 'help', 'code'
             ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
      <hr/>
      {(myData != undefined) ? <NewsCategories id={myData.id} toastifySuccess={popup.toastifySuccess} /> : ''}
      <hr />
    </>
  );
};

export default newsForm;