import { useState, useEffect } from "react";
import AxiosInstance from "../Axios";
import { Card } from "react-bootstrap";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import PageInfoForm from "./PageInfoForm";
import BasicModal from 'src/components/Modal';
import Button from 'react-bootstrap/Button';
import { CButton } from "@coreui/react";
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { confirmAlert } from "react-confirm-alert";
import { BsSortNumericDown } from 'react-icons/bs';
import { sortObj } from "../store/sortObj";

const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}` } };

const PageInfoList = ({id, type}) => {
    const [pageInfos, setPageInfos] = useState([]);
    const [showModalItem, setShowModalItem] = useState(true);
    const popup = MyToastify();
    const actionShowModalItem = (rez) => {
      setShowModalItem(rez);
      if (!rez)
        getItems();
    }
    const getItems = () => {
        let param = type === 'page' ? {['page.id']: id} : {[type]: id};
        AxiosInstance.get(`/page_infos`, {params: param}).then((response) => {
            if(response.status == 200)
                setPageInfos(response.data['hydra:member']);
        });
    }
    useEffect(() => {
        if(id != undefined){
            getItems();
        }
    }, [id]);

    function Deleted(id) {
        confirmAlert({
          title: 'Ви впевнені що хочите видалити цей пункт ?',
          message: 'Are you sure to do this.',
          buttons: [
            {
              label: 'Так',
              onClick: () => {
                const response = AxiosInstance.delete('/page_infos/' + id, header).then((response) => {
                    getItems();
                });
              }
            },
            {
              label: 'Ні',
              onClick: () => {
                return;
              }
            }
          ]
        });
    };

    return (
        <Card className="mt-5" >
            <Card.Header>Дані сторінки</Card.Header>
            <Card.Body>
                {sortObj(pageInfos, 'sort').map((v) => {
                    return (
                        <Card body className="info-list">
                            <BasicModal
                                show={showModalItem}
                                actionShowModal={actionShowModalItem}
                                title={`Додати модуль`}
                                btn_name={v.key}
                                btn_color="light"
                                content={
                                    <PageInfoForm toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItem} data={v} type={type} />
                                }
                            />
                            <div> 
                                <CButton color="danger" variant="outline" onClick={() => {Deleted(v.id)}}>
                                    <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                                </CButton>
                                <small className='SortPunkt'><BsSortNumericDown/> - {v.sort}</small>
                            </div>
                        </Card>
                    );
                })}
                <hr/>
                <BasicModal
                    show={showModalItem}
                    actionShowModal={actionShowModalItem}
                    title={`Додати модуль`}
                    btn_name={<>+ додати</>}
                    content={
                     <PageInfoForm toastifySuccess={popup.toastifySuccess} parent_id={id} actionShowModal={actionShowModalItem} type={type}/>
                    }
                /> 
            </Card.Body>
              
        </Card>
    );
}


export default PageInfoList;